import { ButtonLink, Divider, Flex, Text } from '@applyboard/crystal-ui'
import {
  EmailOutlineIcon,
  HelpOutlineIcon,
  NewspaperOutlineIcon,
  PhoneFillIcon,
} from '@applyboard/ui-icons'
import styled from '@emotion/styled'

export function Footer(): JSX.Element {
  const tradeMark = (
    <Text variant="bodyS" align="center" contrast="inverted">
      © 2015 – {new Date().getFullYear()} ApplyBoard Inc
    </Text>
  )

  const contactInfoBlock = (
    <Flex direction="column">
      <Flex justify="start" py={1}>
        <ButtonLink
          size="sm"
          emphasis="contained"
          leadIcon={EmailOutlineIcon}
          href="mailto:help@applyboard.com"
          intent="primary"
          m={0}
        >
          help@applyboard.com
        </ButtonLink>
      </Flex>
      <Flex justify="start" py={1}>
        <ButtonLink
          size="sm"
          emphasis="contained"
          leadIcon={PhoneFillIcon}
          href="tel:18449727759"
          intent="primary"
          m={0}
        >
          1-844-972-7759
        </ButtonLink>
      </Flex>
    </Flex>
  )

  /** Not including this yet, but will be added later once links become available */
  const externalLinksBlock = (
    <Flex direction="column">
      <Flex justify="start" py={1}>
        <ButtonLink
          size="sm"
          emphasis="contained"
          leadIcon={HelpOutlineIcon}
          href="https://www.applyboard.com"
          intent="primary"
          m={0}
        >
          FAQ
        </ButtonLink>
      </Flex>
      <Flex justify="start" py={1} pr={6}>
        <ButtonLink
          size="sm"
          emphasis="contained"
          leadIcon={NewspaperOutlineIcon}
          href="https://www.applyboard.com"
          intent="primary"
          m={0}
        >
          Blog
        </ButtonLink>
      </Flex>
    </Flex>
  )

  return (
    <FooterWrapper>
      <Flex direction="column" hideBelow="sm">
        <Flex align="center" justify="start" gap={8} py={3}>
          <Flex px={8}>
            <img alt="Capio Logo" src="/capio-logo-white.svg" />
          </Flex>
          <Divider direction="vertical" />
          {contactInfoBlock}
          {/** TODO: add external links block once we have the relevant links */}
          {/* <Divider direction="vertical" />
          {externalLinksBlock} */}
        </Flex>
        <Flex align="center" justify="center" gap={8} py={3}>
          {tradeMark}
        </Flex>
      </Flex>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.colors.actionPrimary,
  width: '100%',
  justifyContent: 'center',
}))
